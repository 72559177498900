export const MAIN_PAGE = '/';
export const NEW_MAIN_PAGE = '/newMain';
export const ABOUT_PAGE = '/about';
export const BUSINESS_PAGE = '/business';
export const PROJECT_PAGE = '/project';
export const CONTACT_PAGE = '/contact';
export const RECRUIT_PAGE = '/career';

// ---------------------------------------------------------------- 하단바 팝업
export const PRIVACY_PAGE = '/home/privacy';
