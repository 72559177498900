import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';

export const StyledBusiness = styled.div`
    position: relative;
`;

export const StyledBusinessSwiper = styled(Swiper)`
    width: 100%;
    height: ${(props) => props.$height}px;
`;

export const StyledBusinessSwiperSlide = styled(SwiperSlide)`
    p {
        margin: 0;
    }

    .imgItem {
        display: block;
        width: 100%;
        height: 100vh;
        background-image: url('${(props) => props.$img}');
        background-color: rgba(0, 0, 0, 0.3);
        background-blend-mode: multiply;
        background-position: 50%;
        background-size: cover;
    }

    .businessContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 12.5vw;
        height: 100%;
        color: #ffffff;

        .logo {
            height: 100px;
            width: auto;
        }

        .headerText {
            font-weight: 900;
            font-size: 4vw;
            line-height: 5.7919vw;
        }

        .bodyText {
            font-weight: 400;
            font-size: 1.625vw;
            line-height: 2.3531vw;
        }
    }

    @media screen and (min-width: 1599px) {
        .businessContainer {
            padding-left: 200px;

            .logo {
                height: 100px;
            }

            .headerText {
                font-size: 64px;
                line-height: 92.67px;
            }

            .bodyText {
                font-size: 26px;
                line-height: 37.65px;
            }
        }
    }

    @media (max-width: 767px) {
        .businessContainer {
            padding-left: 26.0417px;

            .logo {
                height: 11.9714vw;
            }

            .headerText {
                font-size: 8.3333vw;
                line-height: 12.0664vw;
            }

            .bodyText {
                font-size: 3.3854vw;
                line-height: 4.9023vw;
            }
        }
    }
`;
