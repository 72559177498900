import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import $ from 'jquery';

import * as ROUTES from '../../constants/routes';

import CheckMobile from '../Util/MobileCheck';

import { ChangeLanguage } from '../../_actions/i18nAction';

import {
    MenuCloseIco,
    ArrowIco,
    LangIco,
    MenuHamIco,
    StyledPcNewNavBar,
    StyledMbNewNavBar,
    StyledToggleMenu,
} from './style';

import logoWhite from './css/images/ui_logo_white.png';
import logoBlack from './css/images/ui_logo_black.png';

const NewNavBar = (props) => {
    const { activePage } = props;

    const [scroll, setScroll] = useState(false);
    const [isDropMenuOpen, setDropMenuOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const { lang } = useSelector((state) => state.i18nReducer);
    const { r } = useSelector((state) => state.languageReducer);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isMobile = CheckMobile();

    const pathName = useLocation().pathname;
    const t = r[lang];

    const handleScroll = () => {
        if (window.scrollY >= 10) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    };

    const moveToMainPage = (route) => {
        const ele = document.getElementById('bodyWrapper');
        if (ele !== null) ele.scrollTo({ top: 0, behavior: 'smooth' });
        else window.scrollTo({ top: 0, behavior: 'smooth' });

        $('.toggleMenu').css('transition', '');

        setMenuOpen(false);
        setDropMenuOpen(false);

        navigate(route);
    };

    const handleOpenDropList = () => {
        setDropMenuOpen(!isDropMenuOpen);
    };

    const handleOpenMenu = () => {
        $('.toggleMenu').css('transition', '1s top ease');
        setMenuOpen(!menuOpen);
    };

    const handleChangeLanguage = (lang) => {
        setDropMenuOpen(false);
        dispatch(ChangeLanguage(lang));
    };

    function menuLogoSrc() {
        if (pathName === '/business' || pathName === '/project') {
            return activePage > 0 ? logoBlack : logoWhite;
        } else if (pathName === '/contact') {
            return logoBlack;
        } else {
            return scroll ? logoBlack : logoWhite;
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (menuOpen) document.body.style.overflow = 'hidden';
        else if (!menuOpen) document.body.style.overflow = 'unset';
    }, [menuOpen]);

    return (
        <>
            {isMobile ? (
                <>
                    <StyledMbNewNavBar
                        $activePage={activePage}
                        $path={pathName}
                        $scrolling={scroll}
                    >
                        <img
                            className="logo"
                            src={menuOpen ? logoBlack : menuLogoSrc()}
                            alt=""
                            onClick={() => moveToMainPage(ROUTES.MAIN_PAGE)}
                        />
                        {menuOpen ? (
                            <MenuCloseIco onClick={handleOpenMenu} />
                        ) : (
                            <MenuHamIco
                                onClick={handleOpenMenu}
                                activePage={activePage}
                                path={pathName}
                                scrolling={scroll}
                            />
                        )}
                    </StyledMbNewNavBar>
                    <StyledToggleMenu
                        className="toggleMenu"
                        $menuOpen={menuOpen}
                        $height={`${window.innerHeight}`}
                    >
                        <div className="navButtons">
                            <MenuList
                                items={[
                                    { route: ROUTES.ABOUT_PAGE, label: t['nav_menu_1'] },
                                    { route: ROUTES.BUSINESS_PAGE, label: t['nav_menu_2'] },
                                    { route: ROUTES.PROJECT_PAGE, label: t['nav_menu_3'] },
                                    { route: ROUTES.CONTACT_PAGE, label: t['nav_menu_4'] },
                                ]}
                                currentPath={pathName}
                                onItemClick={moveToMainPage}
                            />
                        </div>
                        <div className="langSelectWrapper">
                            <p
                                className={`${lang === 'ko' ? 'active' : ''}`}
                                onClick={() => {
                                    handleChangeLanguage('ko');
                                }}
                            >
                                KO
                            </p>
                            <div className="line"></div>
                            <p
                                className={`${lang === 'en' ? 'active' : ''}`}
                                onClick={() => {
                                    handleChangeLanguage('en');
                                }}
                            >
                                EN
                            </p>
                            <div className="line"></div>
                            <p
                                className={`${lang === 'jp' ? 'active' : ''}`}
                                onClick={() => {
                                    handleChangeLanguage('jp');
                                }}
                            >
                                JP
                            </p>
                        </div>
                    </StyledToggleMenu>
                </>
            ) : (
                <StyledPcNewNavBar $activePage={activePage} $path={pathName} $scrolling={scroll}>
                    <img
                        className="logo"
                        src={menuLogoSrc()}
                        alt=""
                        onClick={() => moveToMainPage(ROUTES.MAIN_PAGE)}
                    />

                    <div className="NewNavWrapper">
                        <div className="NavButtons">
                            <MenuList
                                items={[
                                    { route: ROUTES.ABOUT_PAGE, label: t['nav_menu_1'] },
                                    { route: ROUTES.BUSINESS_PAGE, label: t['nav_menu_2'] },
                                    { route: ROUTES.PROJECT_PAGE, label: t['nav_menu_3'] },
                                    { route: ROUTES.CONTACT_PAGE, label: t['nav_menu_4'] },
                                ]}
                                currentPath={pathName}
                                onItemClick={moveToMainPage}
                            />
                        </div>
                        <div className="LangWrapper">
                            <div className="LangBtnWrapper" onClick={handleOpenDropList}>
                                <LangIco
                                    activePage={activePage}
                                    path={pathName}
                                    scrolling={scroll}
                                />
                                <p>{lang}</p>
                                <div className={`ico-arrow ${isDropMenuOpen ? 'open' : ''}`}>
                                    <ArrowIco
                                        activePage={activePage}
                                        path={pathName}
                                        scrolling={scroll}
                                    />
                                </div>
                            </div>
                            <ul className={`LangSelectWrapper ${isDropMenuOpen ? 'open' : ''}`}>
                                <li
                                    onClick={() => {
                                        handleChangeLanguage('ko');
                                    }}
                                >
                                    KO
                                </li>
                                <li
                                    onClick={() => {
                                        handleChangeLanguage('en');
                                    }}
                                >
                                    EN
                                </li>
                                <li
                                    onClick={() => {
                                        handleChangeLanguage('jp');
                                    }}
                                >
                                    JP
                                </li>
                            </ul>
                        </div>
                    </div>
                </StyledPcNewNavBar>
            )}
        </>
    );
};

const MenuList = ({ items, currentPath, onItemClick }) => (
    <>
        {items.map((item, i) => (
            <p
                key={`menu-${i}`}
                className={`${currentPath === item.route ? 'selected' : ''}`}
                onClick={() => onItemClick(item.route)}
            >
                {item.label}
            </p>
        ))}
    </>
);

export default NewNavBar;
