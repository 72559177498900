import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';

export const StyledProject = styled.div`
    position: relative;
`;

export const StyledProjectSwiper = styled(Swiper)`
    width: 100%;
    height: ${(props) => props.$height}px;
`;

export const StyledProjectSwiperSlide = styled(SwiperSlide)`
    p {
        margin: 0;
    }

    .projectBanner,
    .videoContainer {
        width: 100%;
        height: 50vh;
    }

    .projectBanner {
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: 50%;
        background-size: cover;

        .bannerTxt {
            color: #fff;
            font-size: 2.0812vw;
            font-weight: 700;
            text-align: center;
        }
    }

    .videoContainer {
        position: relative;
        display: flex;

        .videoWrapper {
            position: relative;
            display: flex;
            align-items: center;
            width: 50%;
            cursor: pointer;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-blend-mode: multiply;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .videoWrapper:hover {
            background-blend-mode: normal;
        }

        .playIcon {
            height: auto;
            left: 0;
            margin: 0.78125rem;
            position: absolute;
            top: 0;
            width: 2.875rem;
        }

        .videoTitle {
            color: #fff;
            font-size: 2.0812vw;
            font-weight: 700;
            margin-left: 4.0625vw;
        }
    }

    @media screen and (min-width: 1599px) {
        .projectBanner {
            .bannerTxt {
                font-size: 33.3px;
            }
        }

        .videoContainer {
            .videoTitle {
                font-size: 33.3px;
                margin-left: 65px;
            }
        }
    }
`;

export const StyledProjectMbSwiperSlide = styled(SwiperSlide)`
    p {
        margin: 0;
    }

    .projectBanner {
        width: 100%;
        height: calc(100vh / 3 * 2);
    }

    .projectBanner {
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: 12% 0;
        background-size: cover;
        padding: 0 4.1666666667vw;

        .bannerTxt {
            color: #fff;
            font-size: 5vw;
            font-weight: 700;
        }
    }

    .videoWrapper {
        position: relative;
        display: flex;
        height: calc(100vh / 3);
        align-items: center;
        width: 100%;
        cursor: pointer;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: multiply;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .videoWrapper:hover {
        background-blend-mode: normal;
    }

    .playIcon {
        position: absolute;
        height: auto;
        margin: 0.66875rem 1.41875rem;
        width: 7.4166666667vw;
        left: 0;
        top: 0;
    }

    .videoTitle {
        color: #fff;
        font-size: 1.125rem;
        font-weight: 700;
        margin-left: 1.1875rem;
    }
`;
