import React from 'react';

import BannerSection from './BannerSection';
import TechnologySection from './TechnologySection';
import SolutionSection from './SolutionSection';
import NewsSection from './NewsSection';
import NewFooter from '../NewFooter/NewFooter';

const NewMainPage = () => {
    return (
        <div id="mainWrapper">
            <BannerSection />
            <TechnologySection />
            <SolutionSection />
            <NewsSection />
            <NewFooter />
        </div>
    );
};

export default NewMainPage;
