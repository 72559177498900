import styled from 'styled-components';

export const StyledPcNewNavBar = styled.div`
    display: flex;
    position: fixed;
    padding: 0 12.5vw;
    width: 100%;
    align-items: center;
    z-index: 10;
    height: 5vw;

    color: ${(props) =>
        props.$path === '/business' || props.$path === '/project'
            ? props.$activePage > 0
                ? '#000000'
                : '#ffffff'
            : props.$path === '/contact'
            ? '#000000'
            : props.$scrolling
            ? '#000000'
            : '#ffffff'};

    background: ${(props) =>
        props.$path === '/business' || props.$path === '/project'
            ? props.$activePage > 0
                ? '#ffffff'
                : ''
            : props.$path === '/contact'
            ? '#ffffff'
            : props.$scrolling
            ? '#ffffff'
            : ''};

    p {
        margin: 0;
    }

    .logo {
        width: 7.6875vw;
        height: 2.4375vw;
        cursor: pointer;
    }

    .NewNavWrapper {
        display: flex;
        justify-content: flex-end;
        flex: auto;
        gap: 3vw;
        height: 100%;
    }

    .NavButtons {
        display: flex;
        gap: 5.625vw;
        align-items: center;

        > p {
            cursor: pointer;
            font-weight: 300;
            font-size: 1.125vw;
            line-height: 1.6287vw;
            padding: 0 0.75vw;

            &:hover {
                color: #00a9ff;
            }
        }

        > p.selected {
            color: #00a9ff;
            font-weight: 500;
        }
    }

    .LangBtnWrapper {
        display: flex;
        gap: 0.5938vw;
        cursor: pointer;
        align-items: center;
        height: 100%;

        > p {
            font-weight: 400;
            font-size: 1.125vw;
            line-height: 1.6287vw;
            text-transform: uppercase;
        }

        svg {
            width: 1.5vw;
            height: 1.5vw;
        }
    }

    .ico-arrow {
        display: flex;
        align-items: center;
    }

    .ico-arrow.open {
        transform: rotate(-180deg);
    }

    .LangSelectWrapper {
        position: relative;
        list-style: none;
        margin: -0.9375vw 0 0 0;
        padding: 0;
        background: #ffffff;
        color: #000000;
        text-align: center;
        border-radius: 0.625vw;
        height: 0;
        overflow: hidden;

        li {
            cursor: pointer;
            font-weight: 500;
            height: 2vw;
            line-height: 2vw;
            font-size: 0.875vw;

            &:hover {
                color: #00a5fa;
                background: #00a5fa1a;
            }
        }
    }

    .LangSelectWrapper.open {
        border: 0.0625vw solid #dbdbdb;
        height: 6vw;
    }

    @media screen and (min-width: 1599px) {
        padding: 0 200px;
        height: 80px;

        .logo {
            width: 123px;
            height: 39px;
        }

        .NewNavWrapper {
            gap: 48px;
        }

        .NavButtons {
            gap: 90px;

            > p {
                font-size: 18px;
                line-height: 26.06px;
                padding: 0 12px;
            }
        }

        .LangBtnWrapper {
            gap: 9.5px;

            > p {
                font-size: 18px;
                line-height: 26.06px;
            }

            svg {
                width: 24px;
                height: 24px;
            }
        }

        .LangSelectWrapper {
            margin: -15px 0 0 0;
            border-radius: 10px;

            li {
                height: 32px;
                line-height: 32px;
                font-size: 14px;
            }
        }

        .LangSelectWrapper.open {
            border: 1px solid #dbdbdb;
            height: 96px;
        }
    }
`;

export const StyledMbNewNavBar = styled.div`
    display: flex;
    position: fixed;
    justify-content: space-between;
    padding: 0 5.556vw;
    width: 100%;
    align-items: center;
    z-index: 10;
    height: 15.556vw;

    color: ${(props) =>
        props.$path === '/business' || props.$path === '/project'
            ? props.$activePage > 0
                ? '#000000'
                : '#ffffff'
            : props.$path === '/contact'
            ? '#000000'
            : props.$scrolling
            ? '#000000'
            : '#ffffff'};

    background: ${(props) =>
        props.$path === '/business' || props.$path === '/project'
            ? props.$activePage > 0
                ? '#ffffff'
                : ''
            : props.$path === '/contact'
            ? '#ffffff'
            : props.$scrolling
            ? '#ffffff'
            : ''};

    .logo {
        width: 21.026vw;
        height: 6.668vw;
        cursor: pointer;
    }

    > svg {
        width: 6.668vw;
        height: 6.668vw;
        cursor: pointer;
    }
`;

export const StyledToggleMenu = styled.div`
    p {
        margin: 0;
    }
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 9;
    padding: 23.8893vw 5.556vw 9.5052vw;
    width: 100vw;
    height: ${(props) => props.$height}px;
    background: white;

    top: ${(props) => (props.$menuOpen ? '0' : `-${props.$height}px`)};

    .navButtons {
        font-weight: 300;
        font-size: 7.7773vw;
        line-height: 11.2617vw;

        p {
            margin-bottom: 1.8vw;
        }

        p.selected {
            font-weight: 400;
        }
    }

    .langSelectWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2.2656vw;

        p {
            font-weight: 400;
            font-size: 5vw;
            line-height: 7.2396vw;
            color: #eaeaea;
            cursor: pointer;
        }

        p.active {
            color: #000000;
        }
    }

    .line {
        width: 0.2773vw;
        height: 3.6107vw;
        background: #eaeaea;
    }
`;

export const LangIco = (props) => {
    const { activePage, path, scrolling } = props;

    const activeColor = () => {
        if (path === '/business' || path === '/project') {
            return activePage > 0 ? 'black' : 'white';
        } else if (path === '/contact') {
            return 'black';
        } else {
            return scrolling ? 'black' : 'white';
        }
    };

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2056_9721)">
                <circle cx="12" cy="12" r="9.25" stroke={activeColor()} strokeWidth="1.5" />
                <path
                    d="M16.25 12C16.25 14.6702 15.7072 17.0506 14.8647 18.7357C13.9998 20.4656 12.9436 21.25 12 21.25C11.0564 21.25 10.0002 20.4656 9.13529 18.7357C8.29276 17.0506 7.75 14.6702 7.75 12C7.75 9.32976 8.29276 6.9494 9.13529 5.26434C10.0002 3.53443 11.0564 2.75 12 2.75C12.9436 2.75 13.9998 3.53443 14.8647 5.26434C15.7072 6.9494 16.25 9.32976 16.25 12Z"
                    stroke={activeColor()}
                    strokeWidth="1.5"
                />
                <path d="M4 8H20M21 15H3" stroke={activeColor()} strokeWidth="1.5" />
            </g>
            <defs>
                <clipPath id="clip0_2056_9721">
                    <rect width="24" height="24" fill={activeColor()} />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ArrowIco = (props) => {
    const { activePage, path, scrolling } = props;

    const activeColor = () => {
        if (path === '/business' || path === '/project') {
            return activePage > 0 ? 'black' : 'white';
        } else if (path === '/contact') {
            return 'black';
        } else {
            return scrolling ? 'black' : 'white';
        }
    };

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2056_9728)">
                <path
                    d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z"
                    fill={activeColor()}
                />
            </g>
            <defs>
                <clipPath id="clip0_2056_9728">
                    <rect width="24" height="24" fill={activeColor()} />
                </clipPath>
            </defs>
        </svg>
    );
};

export const MenuHamIco = (props) => {
    const { activePage, path, scrolling, onClick } = props;

    return (
        <svg
            onClick={onClick}
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M44.9334 32.1359C44.9334 33.3141 43.9783 34.2693 42.8001 34.2693H8.66678C7.48857 34.2693 6.53345 33.3141 6.53345 32.1359V32.1359C6.53345 30.9577 7.48857 30.0026 8.66678 30.0026H42.8001C43.9783 30.0026 44.9334 30.9577 44.9334 32.1359V32.1359ZM44.9334 21.4693C44.9334 22.6475 43.9783 23.6026 42.8001 23.6026H8.72198C7.54377 23.6026 6.58865 22.6475 6.58865 21.4693V21.4693C6.58865 20.2911 7.54377 19.3359 8.72198 19.3359H42.8001C43.9783 19.3359 44.9334 20.2911 44.9334 21.4693V21.4693Z"
                fill={
                    path === '/business' || path === '/project'
                        ? activePage > 0
                            ? 'black'
                            : 'white'
                        : path === '/contact'
                        ? 'black'
                        : scrolling
                        ? 'black'
                        : 'white'
                }
            />
        </svg>
    );
};

export const MenuCloseIco = (props) => {
    const { onClick } = props;

    return (
        <svg
            onClick={onClick}
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2204_274)">
                <path
                    d="M44.9333 10.3987L41.0659 6.53125L25.7333 21.8638L10.4008 6.53125L6.53333 10.3987L21.8659 25.7313L6.53333 41.0638L10.4008 44.9313L25.7333 29.5987L41.0659 44.9313L44.9333 41.0638L29.6008 25.7313L44.9333 10.3987Z"
                    fill="#1E1E1E"
                />
            </g>
            <defs>
                <clipPath id="clip0_2204_274">
                    <rect
                        width="51.2"
                        height="51.2"
                        fill="white"
                        transform="translate(0.133301 0.132812)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
