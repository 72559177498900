import React from 'react';
import { useSelector } from 'react-redux';

import NewsSection from '../NewMain/NewsSection';
import NewFooter from '../NewFooter/NewFooter';

import './css/About.scss';

const About = () => {
    const { lang } = useSelector((state) => state.i18nReducer);
    const { r } = useSelector((state) => state.languageReducer);
    const t = r[lang];

    const historyList = {
        2024: [
            [t['month'][3], t['about_hisoty_2024_03_1']],
            [t['month'][2], t['about_hisoty_2024_02_1']],
            [t['month'][1], t['about_hisoty_2024_01_1']],
            [t['month'][1], t['about_hisoty_2024_01_2']],
        ],
        2023: [
            [t['month'][12], t['about_hisoty_2023_12_1']],
            [t['month'][12], t['about_hisoty_2023_12_2']],
            [t['month'][11], t['about_hisoty_2023_11_1']],
            [t['month'][11], t['about_hisoty_2023_11_2']],
            [t['month'][11], t['about_hisoty_2023_11_3']],
            [t['month'][11], t['about_hisoty_2023_11_4']],
            [t['month'][10], t['about_hisoty_2023_10_1']],
            [t['month'][8], t['about_hisoty_2023_08_1']],
            [t['month'][5], t['about_hisoty_2023_05_1']],
            [t['month'][5], t['about_hisoty_2023_05_2']],
            [t['month'][5], t['about_hisoty_2023_05_3']],
            [t['month'][2], t['about_hisoty_2023_02_1']],
        ],
        2022: [
            [t['month'][11], t['about_hisoty_2022_11_1']],
            [t['month'][11], t['about_hisoty_2022_11_2']],
            [t['month'][10], t['about_hisoty_2022_10_1']],
            [t['month'][9], t['about_hisoty_2022_9_1']],
            [t['month'][7], t['about_hisoty_2022_7_1']],
            [t['month'][7], t['about_hisoty_2022_7_2']],
        ],
        2021: [
            [t['month'][11], t['about_hisoty_2021_11_1']],
            [t['month'][9], t['about_hisoty_2021_9_1']],
            [t['month'][6], t['about_hisoty_2021_6_1']],
            [t['month'][1], t['about_hisoty_2021_1_1']],
        ],
        2020: [[t['month'][12], t['about_hisoty_2020_12_1']]],
        2019: [
            [t['month'][10], t['about_hisoty_2019_10_1']],
            [t['month'][8], t['about_hisoty_2019_8_1']],
        ],
        2018: [
            [t['month'][8], t['about_hisoty_2018_8_1']],
            [t['month'][5], t['about_hisoty_2018_5_1']],
            [t['month'][5], t['about_hisoty_2018_5_2']],
            [t['month'][2], t['about_hisoty_2018_2_1']],
            [t['month'][1], t['about_hisoty_2018_1_1']],
        ],
    };

    return (
        <div id="contactOuter">
            <div className="inner aboutMain">
                <div className="aboutMainContainer">
                    <div className="aboutTitleText">{t['about_headTitle']}</div>
                    <div
                        className="aboutContentText"
                        dangerouslySetInnerHTML={{
                            __html: t['about_bodyText'],
                        }}
                    ></div>
                </div>
                <div className="mAboutMainContainer">
                    <div
                        className="mAboutTitleText"
                        dangerouslySetInnerHTML={{
                            __html: t['about_headTitle_m'],
                        }}
                    ></div>
                    <div
                        className="mAboutContentText"
                        dangerouslySetInnerHTML={{
                            __html: t['about_bodyText_m'],
                        }}
                    ></div>
                </div>
            </div>
            <div className="inner historyContainer">
                <div className="historyHeaderText">HISTORY</div>
                <div className="history">
                    <div className="historyContentsWrapper">
                        {Object.entries(historyList)
                            .sort(([yearA], [yearB]) => yearB - yearA)
                            .map(([key, value]) => {
                                return (
                                    <HistoryFormat
                                        key={`history-${key}`}
                                        year={key}
                                        contents={value}
                                    />
                                );
                            })}
                    </div>
                </div>
            </div>
            <NewsSection />

            <NewFooter />
        </div>
    );
};

export default About;

const HistoryFormat = (props) => {
    const { lang } = useSelector((state) => state.i18nReducer);
    let { year, contents, className = '' } = props;
    return (
        <div className={`historyContents ${className}`}>
            <div className="historyYear">{year}</div>
            <div className="historyTextWrapper">
                {contents.length > 0 &&
                    contents.map((c, id) => {
                        return (
                            <div className="historyTextContainer" key={id}>
                                <div className="historyMonth">{c[0]}</div>
                                <div
                                    className="historyText"
                                    style={
                                        lang === 'jp'
                                            ? { wordBreak: 'break-all' }
                                            : { wordBreak: 'unset' }
                                    }
                                >
                                    {c[1]}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
