import styled from 'styled-components';

export const StyledFooter = styled.div`
    text-align: center;

    height: ${(props) => (props.$partner ? '42.1875vw' : '17.1875vw')} !important;

    p {
        margin: 0;
    }

    .partnerWrapper {
        background: #fafafa;
        padding: 3.6875vw 0 5.5625vw;
    }

    .partnerTitle {
        font-weight: 900;
        font-size: 3.25vw;
        line-height: 4.7062vw;
        margin-bottom: 1.5vw;
    }

    .partnerTxt {
        font-weight: 400;
        font-size: 1.625vw;
        line-height: 2.3531vw;
        margin-bottom: 3.1875vw;
        word-break: break-all;
    }

    .partnerBtn {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-color: #00a9ff;
        color: #ffffff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        width: 11.4375vw;
        height: 4vw;
        border-radius: 0.625vw;
        font-size: 1.125vw;
    }

    @media screen and (min-width: 1599px) {
        // height: 675px !important;
        height: ${(props) => (props.$partner ? '675px' : '275px')} !important;

        .partnerWrapper {
            padding: 59px 0 89px;
        }

        .partnerTitle {
            font-size: 52px;
            line-height: 75.3px;
            margin-bottom: 24px;
        }

        .partnerTxt {
            font-size: 26px;
            line-height: 37.65px;
            margin-bottom: 51px;
        }

        .partnerBtn {
            width: 183px;
            height: 64px;
            border-radius: 10px;
            font-size: 18px;
        }

        footer {
            background: #1e1e1e;
            color: #ffffff;

            > div {
                width: 1200px;
            }
        }
    }

    @media (max-width: 767px) {
        height: ${(props) => (props.$partner ? '163.6107vw' : '17.1875vw')} !important;

        .partnerWrapper {
            padding: 25.7813vw 13vw 17.7734vw;
        }

        .partnerTitle {
            font-size: 7.2227vw;
            line-height: 10.4583vw;
            font-weight: 700;
            margin-bottom: 2.3607vw;
        }

        .partnerTxt {
            font-size: 4.444vw;
            line-height: 6.4362vw;
            font-weight: 500;
            margin-bottom: 17.5169vw;
        }

        .partnerBtn {
            width: 35.556vw;
            height: 13.3333vw;
            border-radius: 2.7773vw;
            font-size: 3.8893vw;
        }
    }
`;

export const StyledPcFooter = styled.footer`
    background: #1e1e1e;
    color: #ffffff;
    display: flex;
    justify-content: center;
    text-align: left;
    padding: 11.625vw 0 3vw;

    > div {
        width: 75vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footerAd {
        font-weight: 400;
        font-size: 0.8125vw;
        line-height: 1.1763vw;
    }

    .address {
        margin-bottom: 0.25vw;
    }

    .footerPr {
        display: flex;
        align-items: center;
        gap: 6.375vw;

        a {
            color: #ffffff;
            font-weight: 700;
            font-size: 0.8125vw;
            line-height: 1.1763vw;
        }

        img {
            width: 5.0581vw;
            height: 1.6038vw;
        }
    }

    @media screen and (min-width: 1599px) {
        padding: 186px 0 48px;

        > div {
            width: 1200px;
        }

        .footerAd {
            font-size: 13px;
            line-height: 18.82px;
        }

        .address {
            margin-bottom: 4px;
        }

        .footerPr {
            gap: 102px;

            a {
                font-size: 13px;
                line-height: 18.82px;
            }

            img {
                width: 80.93px;
                height: 25.66px;
            }
        }
    }
`;

export const StyledMbFooter = styled.footer`
    text-align: left;
    background: #1e1e1e;
    color: #ffffff;
    padding: 11.1107vw 5.5495vw;

    img {
        width: 22.4818vw;
        height: 7.1289vw;
        margin-bottom: 4.5391vw;
    }

    p,
    a {
        font-weight: 400;
        font-size: 3.6107vw;
        line-height: 5.2292vw;
    }

    .address {
        margin-bottom: 4.5833vw;
    }

    .copyright {
        margin-bottom: 4.5143vw;
    }

    a {
        color: #ffffff;
        font-weight: 700;
    }
`;
