import { Outlet } from 'react-router-dom';

import NewNavBar from './NewNavBar';

export default function NavbarLayout(props) {
    const { activePage } = props;

    return (
        <div>
            <NewNavBar activePage={activePage} />
            <div>
                <Outlet />
            </div>
        </div>
    );
}
