import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as ROUTES from '../../../constants/routes';

import CheckMobile from '../../Util/MobileCheck';

import { StyledFooter, StyledMbFooter, StyledPcFooter } from './style';

import logo from './images/ui_logo_white.png';

const NewFooter = (props) => {
  let { partner = true } = props;

  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.i18nReducer);
  const { r } = useSelector((state) => state.languageReducer);

  const t = r[lang];

  const isMobile = CheckMobile();

  const moveToInquiryPage = () => {
    navigate(ROUTES.CONTACT_PAGE);
  };

  return (
    <StyledFooter className="inner" $partner={partner}>
      {partner && (
        <div className="partnerWrapper">
          <p className="partnerTitle">Contact Us</p>
          <p className="partnerTxt">{t['footer_partnership_2']}</p>
          <div className="partnerBtn" onClick={moveToInquiryPage}>
            {t['footer_partnership_3']}
          </div>
        </div>
      )}

      {isMobile ? (
        <StyledMbFooter>
          <img src={logo} alt="logo img" />
          <p className="address">Head Office : {t['footer_address_1']}</p>
          <p className="address">Singapore Office : {t['footer_singapore_address']}</p>
          <p className="copyright">Copyrights © 2018 All Rights Reserved by YMX</p>
          <a target="_blank" rel="noopener noreferrer" href={ROUTES.PRIVACY_PAGE}>
            {t['footer_privacy']}
          </a>
        </StyledMbFooter>
      ) : (
        <StyledPcFooter>
          <div>
            <div className="footerAd">
              <p className="address">Head Office : {t['footer_address_1']}</p>
              <p className="address">Singapore Office : {t['footer_singapore_address']}</p>
              <p>Copyrights © 2018 All Rights Reserved by YMX</p>
            </div>
            <div className="footerPr">
              <a target="_blank" rel="noopener noreferrer" href={ROUTES.PRIVACY_PAGE}>
                {t['footer_privacy']}
              </a>
              <img src={logo} alt="logo img" />
            </div>
          </div>
        </StyledPcFooter>
      )}
    </StyledFooter>
  );
};

export default NewFooter;
