import React from 'react';
import './css/Privacy.css';

const Privacy = () => {
    return (
        <div className='privacyWrapper' id="content" style={{padding:'40px'}}>
                <h1>개인정보 취급방침</h1>
                <div class="edit-article">
                    <p>
                        (주)와이엠엑스(이하 "회사"라 합니다)는 회사가 제공하는 서비스 회원의
                        개인정보보호를 매우 중요시하며, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등 개인정보와 관련된 법령 상의
                        개인정보보호규정을 준수하고 있습니다.<br/> 회사는 아래와 같이 개인정보취급방침을 명시하여 회원이 회사에 제공한
                        개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치를 취하는지 알려드립니다.<br/>
                        회사의 서비스 개인정보취급방침은 정부의 법률 및 지침의 변경과 당사의 약관 및 내부 정책에 따라 변경될 수 있으며 이를
                        개정하는 경우 회사는 변경사항에 대하여 즉시 서비스 화면에 게시합니다. 회원님께서는 사이트 방문 시 수시로 확인하시기
                        바랍니다.
                    </p>
                </div>

                <h5>1. 회사는 이용하는 서비스의 형태에 따라 다음과
                    같은 개인정보를 수집 및 이용,제공,파기하고 있습니다.</h5>

                <p>① 회사는 회원기반 서비스 제공을 위해 다음과 같이 개인정보를 수집, 이용, 파기합니다.</p>

                <table border = '1'>
                    <thead>
                        <tr>
                            <th class="first start">수집방법</th>
                            <th class="second">수집항목</th>
                            <th class="third">수집 및 이용목적</th>
                            <th class="forth">보유 및 이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="start">회원가입(필수)</td>
                            <td>닉네임, 이메일 주소</td>
                            <td>서비스 이용 및 상담을 위한 이용자 식별</td>
                            <td rowspan="4">회원에서 탈퇴한 후 회원 재가입, 임의해지 등을 반복적으로 행하여 회사가 제공하는
                                할인쿠폰, 이벤트 혜택 등으로 경제상의 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 편법과 불법행위를 하는
                                회원을 차단 하고자 회원 탈퇴 후 1개월 동안 보관합니다.</td>
                        </tr>

                    </tbody>
                </table>
            <br/>	
                ② 서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다.<br/>
                구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 정보통신서비스 제공자가 자동화된 방법으로 생성하여 이를
                저장(수집)하거나, 2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게 변환한 후에 수집하는 경우를
                의미합니다.
            <p></p>

            <p>③ 회사는 이용자의 개인정보를 수집 목적 달성 후 지체 없이 안전한 방법으로 파기 하고 있습니다. 종이에 출력된
                개인정보는 분쇄기로 분쇄하거나 소각하여 파기 하며, 전자적 파일 형태로 저장된 개인정보는 기록을 복구, 재생할 수 없는
                기술적 방법을 사용하여 삭제 합니다. 단, 아래와 같이 다른 법령 또는 회사 내부 방침에 의해 보관해야 하는 정보는
                법령에서 규정하고 있는 기간 동안 별도의 DB에서 안전하게 보관 후 파기 합니다. 이렇게 별도로 보관하는 개인정보는
                법령에서 정한 경우가 아니고서는 절대 다른 목적으로 이용되지 않습니다.</p>
            <table border = '1'>
                <thead>
                    <tr>
                        <th class="first start">법률/내부 방침</th>
                        <th class="second">수집항목</th>
                        <th class="third">수집 및 이용목적</th>
                        <th class="forth">보유 및 이용기간</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="start" rowspan="2">통신비밀보호법</td>
                        <td rowspan="2">법원의 영장을 받은 수사기관 요청 시 제공</td>
                        <td>로그기록, IP 등</td>
                        <td>3개월</td>
                    </tr>
                    <tr>
                        <td>전기통신일시, 발신기지국의 위치추적 자료 등</td>
                        <td>12개월</td>
                    </tr>
                    <tr>
                        <td class="start">회사 내부 방침</td>
                        <td>아이디, 이름, 연계정보(CI)</td>
                        <td>임의해지 등을 반복적으로 행하는 등 회사가 제공하는 할인쿠폰, 이벤트 혜택 등의 경제상의 이익을
                            불·편법적으로 수취하는 행위 및 명의도용 등의 불·편법 행위 차단</td>
                        <td>6개월</td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <p>
                ④ 1년 이상 서비스 이용 기록이 없는 이용자의 정보는 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 휴면계정으로 전환됩니다.<br/>
                - 휴면계정 전환 최소 30일 전, 등록된 이메일을 통해 전환 예정 일자를 안내해 드리며, 휴면계정으로 전환을 원하지 않으시는 경우에는 안내 메일을 확인하신 후 로그인 하시면 정상적으로 서비스를 이용하실 수 있습니다.<br/>
                - 휴면계정으로 전환된 이용자의 정보는 별도의 DB에 분리 저장되어 안전하게 관리되며, 이렇게 휴면계정으로 전환되었더라도 로그인 후 간단한 휴면 해제 절차를 통해 언제든지 정상적으로 서비스를 이용하실 수 있습니다.<br/>
            </p>
            <p>⑤ 회사는 만 14세 미만 아동의 개인정보를 보호하기 위하여 회원가입은 만14세 이상만 가능하도록 함으로써
                아동의 개인정보를 수집하지 않습니다. 단, 법정대리인의 동의를 얻은 경우에는 만 14세 미만 이용자의 개인정보를
                수집/이용할 수 있습니다.</p>

            <h5>2. 회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다.</h5>
            <p>
                회사가 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공하는 경우, 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.<br/>
                회사가 포함하고 있는 링크를 클릭(click)하여 타사이트(site)의 페이지로 옮겨 갈 경우 해당 사이트의 개인정보보호정책은 회사와 무관하므로 새로 방문한 사이트의 정책을 검토해보시기 바랍니다.
            </p>

            <h5>3. 이용자 및 법정대리인은 아래와 같은 권리를 행사할 수
                있습니다.</h5>
            <p>
                ① 이용자 및 법정대리인은 언제든지 수집 정보에 대하여 수정, 동의철회, 삭제 등을 요청하실 수 있습니다. 다만, 동의 철회/삭제시 서비스의 일부 또는 전부 이용이 제한될 수 있습니다.<br/>
                ② (조회/수정 및 정정) 홈페이지에서 회원정보수정 메뉴 또는 1:1 상담을 이용하여 처리할 수 있습니다. 처리가 완료될 때까지 해당 정보를 이용하거나 타인에게 제공하지 않을 것입니다. 또한 합리적인 사유로 잘못된 개인정보를 제3자에게 이미 제공한 경우, 정정처리의 결과를 지체 없이 제3자에게 통지하여 정정하도록 조치합니다.<br/>
                ③ (동의 철회/삭제) 1:1 상담을 이용하여 수집 정보에 대한 동의 철회 및 삭제를 요청하실 수 있습니다. 다만, 수집정보에 대한 동의 철회/삭제시 서비스의 일부 또는 전부 이용이 제한될 수 있으며, 다른 법령에 따라 수집하는 정보의 경우에는 동의 철회가 어려울 수 있습니다.<br/>
                ④ (회원 탈퇴) 홈페이지의 ‘마이홈&gt;설정&gt;회원정보수정&gt;회원탈퇴’ 메뉴를 이용하여 편리하게 직접 탈퇴를 진행하시거나, 1:1상담을 통하여 진행할 수 있습니다.<br/>
                ⑤ (서비스 재이용) ‘아이디 찾기’를 통하여 미이용자 여부를 확인할 수 있으며, 반드시 비밀번호를 변경하신 후 계정 재이용이 가능합니다. 문의사항은 고객센터 1670-0876을 이용하시기 바랍니다.<br/>
                ⑥ (상담/문의) 고객센터를 통한 소비자 상담/문의 시 녹음 등에 대한 안내는 고객센터 상담 전 안내멘트를 통해 확인하실 수 있습니다.
            </p>

            <h5>4. 이용자 및 법정대리인은 아래와 같은 권리를 행사할 수 있습니다.</h5>
            <p>
                회사는 이용자의 정보를 수시로 저장하고 찾아내는 쿠키(cookie) 등을 운용합니다. 쿠키는 웹사이트가 이용자의
                컴퓨터 브라우저에 보내는 아주 작은 텍스트 파일로 이용자의 컴퓨터 하드디스크에 저장됩니다.
            </p>
            <p>
                ① 쿠키의 사용 목적<br/>
                - 회원과 비회원의 접속 빈도나 방문 시간 등의 분석, 이용 형태 및 관심분야 파악, 자취 추적, 각종 이벤트 참여 정도, 방문 횟수 파악 등을 통한 타겟 마케팅 등 개인별 맞춤 서비스를 제공하기 위해 쿠키를 사용합니다.
            </p>
            <p>
                ② 쿠키의 설치, 운영과 거부<br/> - 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 언제든지 이러한 쿠키의
                저장을 거부하거나 삭제할 수 있습니다.<br/> - 쿠키의 설정은 웹브라우저별 옵션 선택을 통해 ①모든 쿠키를 허용,
                ②쿠키가 저장될 때 마다 확인, ③모든 쿠키의 저장을 거부 할 수 있습니다.<br/> - 이러한 쿠키의 설정 방법은
                웹브라우저에 따라 차이가 있으므로, 자세한 사항은 각 브라우저별 도움말을 참고해 주시기 바랍니다.<br/> -
                Internet Explorer : 도구 메뉴 선택 &gt; 인터넷 옵션 선택 &gt; 개인정보 탭 클릭 &gt; 개인정보처리 수준 설정<br/>
                - Chrome : 설정 메뉴 선택 &gt; 고급 설정 표시 선택 &gt; 개인정보-콘텐츠 설정 선택 &gt; 쿠키 수준 설정<br/>
                - firefox : 옵션 메뉴 선택 &gt; 개인정보 선택 &gt; 방문기록-사용자 정의 설정 &gt; 쿠키 수준 설정<br/> -
                safari : 환경설정 메뉴 선택 &gt; 개인정보 탭 선택 &gt; 쿠키 및 웹 사이트 데이터 수준 설정<br/> - 단,
                쿠키의 저장을 거부하였을 경우에는 로그인이 필요한 서비스 등 일부 서비스 이용에 어려움이 있을 수 있습니다.<br/>
            </p>

            <h5>5. 회사는 이용자들의 개인정보를 처리함에 있어 개인정보의 기술적/관리적 보호 대책을 마련하여 정보보호를 위해 노력하고 있습니다.</h5>
            <p>회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성
                확보를 위하여 다음과 같은 기술적/관리적 보호대책을 강구하고 있습니다.</p>
            <p>
                ① 비밀번호의 암호화<br/> - 이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인 및
                변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
            </p>
            <p>
                ② 해킹 등에 대비한 대책<br/> - 회사는 해킹이나 바이러스 등 회사 정보통신망 침입에 의한 이용자 개인정보의 분실,
                도난, 유출, 변조 또는 훼손 등 피해 방지를 위해 침입탐지 및 침입차단 시스템을 24시간 가동하고 있으며, 만일의 사태에
                대비하여 모든 침입탐지 시스템과 침입차단 시스템은 이중화로 구성하여 운영하고 있습니다.<br/> - 개인정보의 훼손
                피해에 대비하여 중요한 데이터를 주기적으로 백업하고 있으며, 백신 프로그램을 이용하여 개인정보나 중요한 데이터의 유출
                방지를 위해 노력하고 있습니다.<br/> - 민감한 개인정보는 정보통신망을 통해 주고받는 과정에서 암호화 통신을 적용하여
                개인정보를 안전하게 전송할 수 있도록 하고 있습니다.<br/> - 이 외에도 보안 시스템 도입 및 전문인력 확충 등
                보안성 확보를 위해 지속적으로 노력하고 있습니다.
            </p>
            <p>
                ③ 개인정보 처리자의 최소화 및 주기적인 교육<br/> - 회사의 개인정보 관련 처리자는 담당자로 한정하여 개인정보
                처리자를 최소화 하고 있으며, 퇴직 및 직무변경 등 인사이동이 있는 경우 지체 없이 권한을 변경, 말소 하여 개인정보 접근
                권한을 통제 하고 있습니다.<br/> - 개인정보 처리자에 대해 주기적으로 교육을 진행하여 개인정보보호의 중요성을
                인식시키고, 안전하게 관리 할 수 있도록 최선의 노력을 다하고 있습니다.
            </p>

            <h5>6. (주)와이엠엑스는 귀하의 안전한 개인정보 보호를 위해 개인정보 보호책임자를 지정하고 있습니다.</h5>

            <p>① 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
            <table border = '1'>
                <thead>
                    <tr>
                        <th class="half start">개인정보보호책임자</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="start">
                            성명 : 조현준<br/>
                            연락처 : 02-508-7404 (평일 : 10시 ~ 17시 까지)<br/>
                            이메일 : admin@ymx.co.kr
                        </td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <p>
                ② 회사가 제공하는 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제
                등에 관한 사항을 개인정보 보호책임자 및 고객지원센터로 문의하실 수 있으며, (주)와이엠엑스는 이런 문의에 대해 지체 없이
                답변 및 처리 할 것입니다
            </p>

            <p>
                ③ 개인정보가 침해 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/>
                ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영) : (국번없이) 118 / privacy.kisa.or.kr<br/>
                ▶ 대검찰청 사이버범죄수사단 : (국번없이) 1301 / www.spo.go.kr<br/>
                ▶ 경찰청 사이버안전국 : (국번없이) 182 / www.cyber.go.kr<br/>
                ▶ 전자거래분쟁조정위원회 (https://www.ecmc.or.kr / 1661-5714)<br/>
            </p>

            <h5>7. 개인정보 처리방침 변경에 관한 정책은 다음과 같습니다.</h5>
            <p>
                이 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는
                변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다. 또한 관련 법령이나 회사 정책의 변경으로 불가피하게
                처리방침을 변경해야 하는 경우, 웹사이트 공지사항을 통해 빠르게 알려드리고 있으니 참고하여 주시기 바랍니다.
            </p>
        </div>
    );
};

export default Privacy;