import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Mousewheel } from 'swiper';

import CheckDevice from '../../Util/DeviceCheck';
import CheckMobile from '../../Util/MobileCheck';
import { FrameYoutubeModal } from '../../Util/FrameYoutube';

import NewFooter from '../NewFooter/NewFooter';

import bannerBg from './images/ui_bg_banner.png';

import thumbBg1 from './images/ui_bg_inspection_ar@3x.png';
import thumbBg2 from './images/ui_bg_monitoring@3x.png';
import thumbBg3 from './images/ui_bg_ar_service@3x.png';
import thumbBg4 from './images/ui_bg_inspection_site@3x.png';
import thumbBg5 from './images/ui_bg_equipment@3x.png';

import emptyVideoThumbnail1 from './images/ui_bg_non@3x.png';
import emptyVideoThumbnail2 from './images/03_EPC_00546.jpg';

import playIcon from './images/ui_ic_play_circle_outline@3x.png';

import mouseImg from './images/ui_ic_mouse@3x.png';
import goUpIcon from './images/ui_btn_top_btn@2x.png';

import a_1_1 from './images/client/ui_ic_client_samsung.png';
import a_1_2 from './images/client/ui_ic_client_sds.png';
import a_1_3 from './images/client/ui_ic_client_samsung_che.png';

import a_2_1 from './images/client/ui_ic_client_lg.png';
import a_2_2 from './images/client/ui_ic_client_lgchem.png';
import a_2_3 from './images/client/ui_ic_client_lgdisplay.png';

import a_3_1 from './images/client/ui_ic_client_doosan_infracore.png';
import a_3_2 from './images/client/ui_ic_client_hyundai.png';
import a_3_3 from './images/client/ui_ic_client_daewoo.png';

import a_4_1 from './images/client/ui_ic_client_kai.png';
import a_4_2 from './images/client/ui_ic_client_kari.png';
import a_4_3 from './images/client/ui_ic_client_korea.png';

import b_1_1 from './images/client/ui_ic_client_display.png';
import b_1_2 from './images/client/ui_ic_client_unity.png';
import b_1_3 from './images/client/ui_ic_client_ptc.png';

import b_2_1 from './images/client/ui_ic_client_lgcns.png';
import b_2_2 from './images/client/ui_ic_client_lgensol.png';
import b_2_3 from './images/client/ui_ic_client_gs.png';

import b_3_1 from './images/client/ui_ic_client_rockwell.png';
import b_3_2 from './images/client/ui_ic_client_olive.png';
import b_3_3 from './images/client/ui_ic_client_ebay.png';

import b_4_1 from './images/client/ui_ic_client_busan.png';
import b_4_2 from './images/client/ui_ic_client_yonsei.png';
import b_4_3 from './images/client/ui_ic_client_nsu.png';

import {
    StyledProject,
    StyledProjectSwiper,
    StyledProjectSwiperSlide,
    StyledProjectMbSwiperSlide,
} from './style';

const NewProjectPage = (props) => {
    const { activePage } = props;

    const [mouseVertMove, setMouseVertMove] = useState(true);

    const [swiper, setSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const { state } = useLocation();

    const { lang } = useSelector((state) => state.i18nReducer);
    const { r } = useSelector((state) => state.languageReducer);
    const t = r[lang];

    const isMobile = CheckMobile();

    const urlWholeArr = [
        { keyValue: 'AXzDXGzDKR4', title: t['project_videoTitle_1'], thumbnail: thumbBg1 },
        { keyValue: '1qSTI3RQL9Y', title: t['project_videoTitle_2'], thumbnail: thumbBg2 },
        { keyValue: 'yy8TN8p2iiU', title: t['project_videoTitle_3'], thumbnail: thumbBg3 },
        { keyValue: 'RaU3TwR-QAs', title: t['project_videoTitle_4'], thumbnail: thumbBg4 },
        { keyValue: 'iKlipuobol4', title: t['project_videoTitle_5'], thumbnail: thumbBg5 },
    ];

    const projectList = {
        pc: [
            [
                [
                    {
                        bg: bannerBg,
                        txt: t['project_maintitle'],
                        keyValue: '',
                    },
                ],
                [
                    {
                        bg: urlWholeArr[0].thumbnail,
                        txt: urlWholeArr[0].title,
                        keyValue: urlWholeArr[0].keyValue,
                    },
                    {
                        bg: urlWholeArr[1].thumbnail,
                        txt: urlWholeArr[1].title,
                        keyValue: urlWholeArr[1].keyValue,
                    },
                ],
            ],
            [
                [
                    {
                        bg: urlWholeArr[2].thumbnail,
                        txt: urlWholeArr[2].title,
                        keyValue: urlWholeArr[2].keyValue,
                    },
                    {
                        bg: urlWholeArr[3].thumbnail,
                        txt: urlWholeArr[3].title,
                        keyValue: urlWholeArr[3].keyValue,
                    },
                ],
                [
                    {
                        bg: urlWholeArr[4].thumbnail,
                        txt: urlWholeArr[4].title,
                        keyValue: urlWholeArr[4].keyValue,
                    },
                    {
                        bg: emptyVideoThumbnail1,
                        txt: '',
                        keyValue: '',
                    },
                ],
            ],
        ],
        mobile: [
            [
                {
                    bg: bannerBg,
                    txt: t['project_maintitle_m'],
                    keyValue: '',
                },
                {
                    bg: urlWholeArr[0].thumbnail,
                    txt: urlWholeArr[0].title,
                    keyValue: urlWholeArr[0].keyValue,
                },
            ],
            [
                {
                    bg: urlWholeArr[1].thumbnail,
                    txt: urlWholeArr[1].title,
                    keyValue: urlWholeArr[1].keyValue,
                },
                {
                    bg: urlWholeArr[2].thumbnail,
                    txt: urlWholeArr[2].title,
                    keyValue: urlWholeArr[2].keyValue,
                },
                {
                    bg: urlWholeArr[3].thumbnail,
                    txt: urlWholeArr[3].title,
                    keyValue: urlWholeArr[3].keyValue,
                },
            ],
            [
                {
                    bg: urlWholeArr[4].thumbnail,
                    txt: urlWholeArr[4].title,
                    keyValue: urlWholeArr[4].keyValue,
                },
                {
                    bg: emptyVideoThumbnail1,
                    txt: '',
                    keyValue: '',
                },
                {
                    bg: emptyVideoThumbnail2,
                    txt: '',
                    keyValue: '',
                },
            ],
        ],
    };

    const [modalData, setModalData] = useState({
        videoKey: null,
        isMobile: false,
    });
    const [videoModalVisible, setVideoModalVisible] = useState(false);

    const openYoutubeModal = (videoKey, e) => {
        setModalData({
            videoKey: videoKey,
            isMobile: !!e.touches,
        });
        setVideoModalVisible(true);
    };
    const modalDataReset = () => {
        setModalData({
            videoKey: null,
            isMobile: false,
        });
    };

    const handleOnSlideChange = (swiper) => {
        setActiveSlide(swiper.realIndex);
    };

    const goToTop = () => {
        swiper.slideTo(0, 1000);
    };

    useEffect(() => {
        if (state && swiper) {
            swiper.slideTo(state.index, 0);
        }
    }, [swiper]);

    useEffect(() => {
        activePage(activeSlide);
    }, [activeSlide]);

    useEffect(() => {
        setTimeout(() => {
            setMouseVertMove(false);
        }, 3000);
    }, []);

    return (
        <StyledProject className="inner">
            <StyledProjectSwiper
                direction={'vertical'}
                allowTouchMove={CheckDevice() === 'pc' ? false : true}
                mousewheel={true}
                resistance={true}
                resistanceRatio={0}
                speed={700}
                modules={[Mousewheel]}
                onSwiper={setSwiper}
                onSlideChange={handleOnSlideChange}
                autoHeight={true}
                slidesPerView={'auto'}
                className="projectSwiper"
                $height={`${window.innerHeight}`}
            >
                {isMobile ? (
                    <>
                        {projectList.mobile.map((row, rowIndex) => (
                            <StyledProjectMbSwiperSlide
                                key={`projectSwiperSlide-mobile-${rowIndex}`}
                            >
                                {row.map((project, projectIdx) =>
                                    rowIndex === 0 && projectIdx === 0 ? (
                                        <div
                                            className="projectBanner"
                                            style={{ backgroundImage: `url('${project.bg}')` }}
                                            key={`projectBanner-mobile-${rowIndex}-${projectIdx}`}
                                        >
                                            <p
                                                className="bannerTxt"
                                                dangerouslySetInnerHTML={{ __html: project.txt }}
                                            />
                                        </div>
                                    ) : (
                                        <VideoThumbnail
                                            key={`videoThumbnail-mobile-${rowIndex}-${projectIdx}`}
                                            thumbnail={project.bg}
                                            title={project.txt}
                                            videoKey={project.keyValue}
                                            openModal={openYoutubeModal}
                                        />
                                    )
                                )}
                            </StyledProjectMbSwiperSlide>
                        ))}
                    </>
                ) : (
                    <>
                        {projectList.pc.map((row, rowIndex) => (
                            <StyledProjectSwiperSlide key={`projectSwiperSlide-pc-${rowIndex}`}>
                                {row.map((project, projectIdx) =>
                                    project.length < 2 ? (
                                        <div
                                            className="projectBanner"
                                            style={{ backgroundImage: `url('${project[0].bg}')` }}
                                            key={`projectBanner-${rowIndex}-${projectIdx}`}
                                        >
                                            <p
                                                className="bannerTxt"
                                                dangerouslySetInnerHTML={{ __html: project[0].txt }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className="videoContainer"
                                            key={`videoContainer-${rowIndex}-${projectIdx}`}
                                        >
                                            {project.map((projectItem, itemIdx) => (
                                                <VideoThumbnail
                                                    key={`videoThumbnail-${rowIndex}-${projectIdx}-${itemIdx}`}
                                                    thumbnail={projectItem.bg}
                                                    title={projectItem.txt}
                                                    videoKey={projectItem.keyValue}
                                                    openModal={openYoutubeModal}
                                                />
                                            ))}
                                        </div>
                                    )
                                )}
                            </StyledProjectSwiperSlide>
                        ))}
                    </>
                )}
                <StyledProjectSwiperSlide>
                    <div
                        className="inner"
                        style={{ border: '1px solid transparent', height: '100vh' }}
                    >
                        <div className="carouselWrapper">
                            <div className="shrinkBlank"></div>
                            <div className="clientHeaderWrppaer">
                                <div className="clientHeader">CLIENT</div>
                                <ClientList />
                            </div>
                            <div className="shrinkBlank"></div>
                        </div>
                    </div>
                </StyledProjectSwiperSlide>
                <StyledProjectSwiperSlide>
                    <NewFooter />
                </StyledProjectSwiperSlide>
            </StyledProjectSwiper>
            <FrameYoutubeModal
                modalData={modalData}
                modalDataReset={modalDataReset}
                visible={videoModalVisible}
                setvisible={setVideoModalVisible}
            />
            <div
                id="mouseIcon"
                className={mouseVertMove ? 'vert-move mouseIcon' : 'displayNone mouseIcon'}
            >
                <img src={mouseImg} />
            </div>
            <img className="goUpIcon cursorPointer" src={goUpIcon} onClick={goToTop} />
        </StyledProject>
    );
};

export default NewProjectPage;

const VideoThumbnail = ({ thumbnail, title, videoKey, openModal }) => {
    return (
        <div
            className="videoWrapper"
            style={{ backgroundImage: `url('${thumbnail}')` }}
            onClick={(e) => videoKey && openModal(videoKey, e)}
        >
            {videoKey && <img src={playIcon} className="playIcon" />}
            <p className="videoTitle" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
    );
};

const ClientList = () => {
    const clientImg = [
        [a_1_1, a_1_2, a_1_3, b_1_1, b_1_2, b_1_3],
        [a_2_1, a_2_2, a_2_3, b_2_1, b_2_2, b_2_3],
        [a_3_1, a_3_2, a_3_3, b_3_1, b_3_2, b_3_3],
        [a_4_1, a_4_2, a_4_3, b_4_1, b_4_2, b_4_3],
    ];

    return (
        <div className="clientViewContainer">
            <div className="clientListContainer">
                {[1, 2].map((listNum) => (
                    <div className="clientList" key={`clientList${listNum}`}>
                        {clientImg.map((imgList, id) => (
                            <div className="clientRow" key={`clinetImgList${listNum}-${id}`}>
                                {imgList.map((imgUrl, i) => (
                                    <div key={`clingImg${listNum}-${i}`}>
                                        <img src={imgUrl} alt="" />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};
