import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';

import * as ROUTES from '../../../constants/routes';

import CheckMobile from '../../Util/MobileCheck';

import {
    StyledSolution,
    StyledSolutionSwiper,
    StyledSolutionMbSwiperSlide,
    StyledSolutionPcSwiperSlide,
    SwiperMbMoreViewBtn,
    SwiperPcMoreViewBtn,
    SwiperNextBtn,
    SwiperPrevBtn,
} from './style';

import mxspaceLogo from './images/solution/mxspace_logo.png';
import mxviewLogo from './images/solution/mxview_logo.png';
import mx3dOptimizerLogo from './images/solution/mx3d-optimizer_logo.png';
import mxdtsLogo from './images/solution/mxdts_logo.png';
import mxatgLogo from './images/solution/mxatg_logo.png';

import mxspaceImg from '../../common/images/ui_img_ex_mxspace.png';
import mxviewImg from '../../common/images/ui_img_ex_mxview.png';
import mx3dOptimizerImg from '../../common/images/ui_img_ex_mx3doptimizer.png';
import mxdtsImg from '../../common/images/ui_img_ex_mxdts.png';
import mxatgImg from '../../common/images/ui_img_ex_mxatg.png';

const SolutionSection = () => {
    const [activeSlide, setActiveSlide] = useState(0);

    const { lang } = useSelector((state) => state.i18nReducer);
    const { r } = useSelector((state) => state.languageReducer);

    const navigate = useNavigate();

    const t = r[lang];

    const isMobile = CheckMobile();

    const solutionSwiper = [
        {
            paginationTitle: 'MXspace',
            logo: mxspaceLogo,
            txt: t['newMainPage_solution_MXspace_txt'],
            img: mxspaceImg,
        },
        {
            paginationTitle: 'MXview',
            logo: mxviewLogo,
            txt: isMobile
                ? t['newMainPage_solution_pc_MXview_txt']
                : t['newMainPage_solution_pc_MXview_txt'],
            img: mxviewImg,
        },
        {
            paginationTitle: 'MX3d-optimizer',
            logo: mx3dOptimizerLogo,
            txt: isMobile
                ? t['newMainPage_solution_mb_MX3d_optimizer_txt']
                : t['newMainPage_solution_pc_MX3d_optimizer_txt'],
            img: mx3dOptimizerImg,
        },
        {
            paginationTitle: 'MXdts',
            logo: mxdtsLogo,
            txt: t['newMainPage_solution_MXdts_txt'],
            img: mxdtsImg,
        },
        {
            paginationTitle: 'MXatg',
            logo: mxatgLogo,
            txt: isMobile
                ? t['newMainPage_solution_mb_MXatg_txt']
                : t['newMainPage_solution_pc_MXatg_txt'],
            img: mxatgImg,
        },
    ];

    const handleOnSlideChange = (swiper) => {
        setActiveSlide(swiper.realIndex);
    };

    const handleMoveBusiness = () => {
        navigate(`${ROUTES.BUSINESS_PAGE}`, {
            state: { index: activeSlide },
        });
    };

    return (
        <StyledSolution>
            <div>
                <p className="solutionTitle">Solution</p>

                <div className="solutionPagination"></div>

                <StyledSolutionSwiper
                    modules={[Navigation, Pagination, EffectFade, Autoplay]}
                    loop
                    slideToClickedSlide
                    // autoplay={{ delay: 3000, disableOnInteraction: false }}
                    speed={500}
                    effect="fade"
                    autoHeight={true}
                    pagination={{
                        clickable: true,
                        el: '.solutionPagination',
                        renderBullet: function (index, className) {
                            return `<span class="${className} ">${solutionSwiper[index].paginationTitle}</span>`;
                        },
                    }}
                    navigation={{
                        prevEl: '.solution-swiper-navi-prev',
                        nextEl: '.solution-swiper-navi-next',
                    }}
                    onSlideChange={handleOnSlideChange}
                >
                    {solutionSwiper.map((solution, i) => {
                        return (
                            <React.Fragment key={`solution-${i}`}>
                                {isMobile ? (
                                    <StyledSolutionMbSwiperSlide key={`mb-solution-${i}`}>
                                        <p
                                            className="solutionTxt"
                                            dangerouslySetInnerHTML={{
                                                __html: solution.txt,
                                            }}
                                        />
                                        <img className="solutionLogo" src={solution.logo} alt="" />
                                        <img className="solutionImg" src={solution.img} alt="" />
                                    </StyledSolutionMbSwiperSlide>
                                ) : (
                                    <StyledSolutionPcSwiperSlide key={`pc-solution-${i}`}>
                                        <div className="solutionWrapper">
                                            <div className="solutionContent">
                                                <div>
                                                    <img
                                                        className="solutionLogo"
                                                        src={solution.logo}
                                                        alt=""
                                                    />
                                                    <p
                                                        className={`solutionTxt ${lang}`}
                                                        dangerouslySetInnerHTML={{
                                                            __html: solution.txt,
                                                        }}
                                                    />
                                                </div>
                                                <SwiperPcMoreViewBtn onClick={handleMoveBusiness} />
                                            </div>
                                            <div className="solutionImageWrapper">
                                                <img
                                                    className="solutionImg"
                                                    src={solution.img}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </StyledSolutionPcSwiperSlide>
                                )}
                            </React.Fragment>
                        );
                    })}

                    <div className="swiperNaviWrapper">
                        {isMobile && (
                            <SwiperMbMoreViewBtn
                                className="mbMoreBtn"
                                onClick={handleMoveBusiness}
                            />
                        )}
                        <div>
                            <div className="solution-swiper-navi-prev">
                                <SwiperPrevBtn />
                            </div>
                            <div className="solution-swiper-navi-next">
                                <SwiperNextBtn />
                            </div>
                        </div>
                    </div>
                </StyledSolutionSwiper>
            </div>
        </StyledSolution>
    );
};

export default SolutionSection;
