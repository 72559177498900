import {
    resources, supportedLangs
  } from "../i18n/i18n";

const initialState = {
    r : resources,
    supportedLangs: { ...supportedLangs }
}
export default function languageReducer (state = initialState, action) {
    switch (action.type) {
        default:{
            return state;
        }
    }
}

