import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Mousewheel } from 'swiper';

import CheckDevice from '../../Util/DeviceCheck';

import NewFooter from '../NewFooter/NewFooter';

import MXspace from './images/ui_logo_space_mxspace_white@3x.png';
import MXspaceBg from '../../common/images/ui_img_ex_mxspace.png';

import MXview from './images/ui_logo_space_mxview_white@3x.png';
import MXviewBG from '../../common/images/ui_img_ex_mxview.png';

import MX3dOptimizer from './images/ui_logo_space_mx3d-optimizer_white@3x.png';
import MX3dOptimizerBG from '../../common/images/ui_img_ex_mx3doptimizer.png';

import dts from './images/ui_logo_default_space_mxdts_white.png';
import dtsBg from '../../common/images/ui_img_ex_mxdts.png';

import Mxatg from './images/ui_logo_default_space_mxatg_white@3x.png';
import MxatgBg from '../../common/images/ui_img_ex_mxatg.png';

import mouseImg from './images/ui_ic_mouse@3x.png';
import goUpIcon from './images/ui_btn_top_btn@2x.png';

import { StyledBusiness, StyledBusinessSwiper, StyledBusinessSwiperSlide } from './style';

const NewBusinessPage = (props) => {
    const { activePage } = props;

    const [mouseVertMove, setMouseVertMove] = useState(true);

    const [swiper, setSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const { state } = useLocation();

    const { lang } = useSelector((state) => state.i18nReducer);
    const { r } = useSelector((state) => state.languageReducer);
    const t = r[lang];

    const businessList = [
        {
            bgImage: MXspaceBg,
            logo: MXspace,
            headerText: t['business_MXspace_title'],
            bodyText: t['business_MXspace_body'],
        },
        {
            bgImage: MXviewBG,
            logo: MXview,
            headerText: t['business_MXview_title'],
            bodyText: t['business_MXview_body'],
        },
        {
            bgImage: MX3dOptimizerBG,
            logo: MX3dOptimizer,
            headerText: t['business_MX3d_optimizer_title'],
            bodyText: t['business_MX3d_optimizer_body'],
        },
        {
            bgImage: dtsBg,
            logo: dts,
            headerText: t['business_MXdts_title'],
            bodyText: t['business_MXdts_body'],
        },
        {
            bgImage: MxatgBg,
            logo: Mxatg,
            headerText: t['business_Mxtsg_title'],
            bodyText: t['business_Mxtsg_body'],
        },
    ];

    const handleOnSlideChange = (swiper) => {
        setActiveSlide(swiper.realIndex);
    };

    const goToTop = () => {
        swiper.slideTo(0, 1000);
    };

    useEffect(() => {
        if (state && swiper) {
            swiper.slideTo(state.index, 0);
        }
    }, [swiper]);

    useEffect(() => {
        activePage(activeSlide);
    }, [activeSlide]);

    useEffect(() => {
        setTimeout(() => {
            setMouseVertMove(false);
        }, 3000);
    }, []);

    return (
        <StyledBusiness>
            <StyledBusinessSwiper
                direction={'vertical'}
                allowTouchMove={CheckDevice() === 'pc' ? false : true}
                mousewheel={true}
                resistance={true}
                resistanceRatio={0}
                speed={700}
                modules={[Mousewheel]}
                onSwiper={setSwiper}
                onSlideChange={handleOnSlideChange}
                autoHeight={true}
                slidesPerView={'auto'}
                className="businessSwiper"
                $height={`${window.innerHeight}`}
            >
                {businessList.map((businessList, i) => {
                    return (
                        <StyledBusinessSwiperSlide
                            $img={businessList.bgImage}
                            key={`business-${i}`}
                        >
                            <div className="imgItem">
                                <div className="businessContainer">
                                    <img className="logo" src={businessList.logo} alt="" />
                                    <p
                                        className="headerText"
                                        dangerouslySetInnerHTML={{
                                            __html: businessList.headerText,
                                        }}
                                    />
                                    <p
                                        className="bodyText"
                                        dangerouslySetInnerHTML={{
                                            __html: businessList.bodyText,
                                        }}
                                    />
                                </div>
                            </div>
                        </StyledBusinessSwiperSlide>
                    );
                })}
                <StyledBusinessSwiperSlide>
                    <NewFooter />
                </StyledBusinessSwiperSlide>
            </StyledBusinessSwiper>
            <div
                id="mouseIcon"
                className={mouseVertMove ? 'vert-move mouseIcon' : 'displayNone mouseIcon'}
            >
                <img src={mouseImg} />
            </div>
            <img className="goUpIcon cursorPointer" src={goUpIcon} onClick={goToTop} />
        </StyledBusiness>
    );
};

export default NewBusinessPage;
