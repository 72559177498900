import React, { useEffect, useState } from 'react';
import './css/ContactMap.css';
import Map from './Map';
import { useSelector } from 'react-redux';

const ContactMap = () => {
  const { lang } = useSelector((state) => state.i18nReducer);
  const { r } = useSelector((state) => state.languageReducer);
  const [value, setValue] = useState(false);
  const t = r[lang];
  useEffect(() => {
    setValue(true);
  }, []);
  return (
    <div className="contactMapWrapper">
      <div className="contactMapContainer">
        <div className="mapWrapper" id="mapWrapper">
          <Map />
        </div>
        <div className="emptyDiv"></div>
        <div className="infoContainer">
          <div className="infoTitle">Head Office</div>
          <div className="infoAddress">
            <div>{t['contact_address_1']}</div>
            <div>{t['contact_address_2']}</div>
            <div>Phone : (02) 465 3737</div>
            <div>{t['contact_menu_1']} : contact@ymx.co.kr</div>
            <div>{t['contact_menu_2']} : support@ymx.co.kr</div>
          </div>

          <div className="infoTitle" style={{ marginTop: 30 }}>
            Singapore Office
          </div>
          <div className="infoAddress">
            <div>{t['contact_singapore_address']}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMap;
