import React from 'react';

import { useSelector } from 'react-redux';

import { Navigation, Autoplay } from 'swiper';

import CheckMobile from '../../Util/MobileCheck';

import {
    StyledNews,
    StyledNewsSwiper,
    StyledNewsSwiperSlide,
    SwiperNextBtn,
    SwiperPrevBtn,
} from './style';

import newsImg01 from './images/news/news01.png';
import newsImg02 from './images/news/news02.png';
import newsImg03 from './images/news/news03.png';
import newsImg04 from './images/news/news04.png';
import newsImg05 from './images/news/news05.png';
import newsImg06 from './images/news/news06.png';
import newsImg07 from './images/news/news07.png';
import newsImg08 from './images/news/news08.png';
import newsImg09 from './images/news/news09.png';
import newsImg10 from './images/news/news010.png';
import newsImg11 from './images/news/news011.png';

const NewsSection = () => {
    const { lang } = useSelector((state) => state.i18nReducer);
    const { r } = useSelector((state) => state.languageReducer);

    const t = r[lang];

    const isMobile = CheckMobile();

    const NewsSwiper = [
        {
            img: newsImg11,
            link: 'https://www.etoday.co.kr/news/view/2319472',
            txt: t['mainNews_txt_11'],
            date: '2024.01.09',
            channel: t['mainNews_channel_e_today'],
        },
        {
            img: newsImg10,
            link: 'https://www.mk.co.kr/news/it/10817227',
            txt: t['mainNews_txt_10'],
            date: '2023.08.29',
            channel: t['mainNews_channel_mk'],
        },
        {
            img: newsImg09,
            link: 'https://biz.chosun.com/it-science/ict/2023/07/27/SOYFYO4ABFCS7GC2YMJR5OTRJM/?utm_source=naver&utm_medium=original&utm_campaign=biz',
            txt: t['mainNews_txt_9'],
            date: '2023.07.27',
            channel: t['mainNews_channel_chosun_biz'],
        },
        {
            img: newsImg08,
            link: 'https://newsis.com/view/?id=NISX20230714_0002377220&cID=13001&pID=13000',
            txt: t['mainNews_txt_8'],
            date: '2023.07.14',
            channel: t['mainNews_channel_newsis'],
        },
        {
            img: newsImg07,
            link: 'https://www.wowtv.co.kr/NewsCenter/News/Read?articleId=A202305240327&t=NN',
            txt: t['mainNews_txt_7'],
            date: '2023.05.24',
            channel: t['mainNews_channel_wowtv'],
        },
        {
            img: newsImg06,
            link: 'https://www.fnnews.com/news/202305111039032102',
            txt: t['mainNews_txt_6'],
            date: '2023.05.11',
            channel: t['mainNews_channel_fn_nfews'],
        },
        {
            img: newsImg05,
            link: 'https://www.etnews.com/20230216000250',
            txt: t['mainNews_txt_5'],
            date: '2023.02.16',
            channel: t['mainNews_channel_et_news'],
        },
        {
            img: newsImg04,
            link: 'https://www.ddaily.co.kr/news/article/?no=253502',
            txt: t['mainNews_txt_4'],
            date: '2022.12.16',
            channel: t['mainNews_channel_d_daily'],
        },
        {
            img: newsImg03,
            link: 'https://n.news.naver.com/mnews/article/215/0001070075?sid=101',
            txt: t['mainNews_txt_3'],
            date: '2022.12.07',
            channel: t['mainNews_channel_wowtv'],
        },
        {
            img: newsImg02,
            link: 'https://n.news.naver.com/article/215/0001066008?sid=105',
            txt: t['mainNews_txt_2'],
            date: '2022.11.16',
            channel: t['mainNews_channel_wowtv'],
        },
        {
            img: newsImg01,
            link: 'https://www.etnews.com/20220915000238',
            txt: t['mainNews_txt_1'],
            date: '2022.09.15',
            channel: t['mainNews_channel_et_news'],
        },
    ];

    return (
        <StyledNews>
            <p className="newsTitle">NEWS</p>
            <div className="newsContents">
                <StyledNewsSwiper
                    modules={[Navigation, Autoplay]}
                    navigation={{
                        prevEl: '.news-swiper-navi-prev',
                        nextEl: '.news-swiper-navi-next',
                    }}
                    loop
                    slidesPerView={3}
                    centeredSlides={isMobile ? true : false}
                >
                    {NewsSwiper.map((news, i) => {
                        return (
                            <StyledNewsSwiperSlide
                                key={`news-${i}`}
                                onClick={() => window.open(`${news.link}`, '_blank')}
                            >
                                <div>
                                    <img className="newsImg" src={news.img} alt="" />
                                    <div className="newsSlide">
                                        <div>
                                            <p
                                                className={`newsTxt ${lang}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: news.txt,
                                                }}
                                            />
                                            <p className="newsDate">{news.date}</p>
                                        </div>
                                        <div className="newsChannel">
                                            <p>{news.channel}</p>
                                        </div>
                                    </div>
                                </div>
                            </StyledNewsSwiperSlide>
                        );
                    })}
                </StyledNewsSwiper>

                <div className="swiper-navi news-swiper-navi-prev">
                    <SwiperPrevBtn />
                </div>

                <div className="swiper-navi news-swiper-navi-next">
                    <SwiperNextBtn />
                </div>
            </div>
        </StyledNews>
    );
};

export default NewsSection;
