import React, { useEffect } from 'react';
import ContactMail from './ContactMail';
import ContactMap from './ContactMap';
import Footer from '../Footer/Footer';
import $ from 'jquery';
import goUpIcon from '../../Scrolling/css/images/ui_btn_top_btn@2x.png';
import NewFooter from '../NewFooter/NewFooter';

const navBarHeight = window.innerWidth <= 800 ? 56 : 80;
const Contact = () => {
    useEffect(() => {
        $('.outer .inner').css('height', window.innerHeight - navBarHeight);
    }, []);
    const goToTop = () => {
        let ele = document.getElementById('contactOuter');
        if (ele !== null) window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <>
            <img className="goUpIcon cursorPointer" src={goUpIcon} onClick={goToTop} />
            <div className="outer" id="contactOuter">
                <ContactMail />
                <ContactMap />
                <NewFooter partner={false} />
            </div>
        </>
    );
};

export default Contact;
